/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");
    window.Popper = require("popper.js").default;

    require("bootstrap");
} catch (e) { }

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
});

window.onSubmitCaptcha = function (token) {
    $("#reCaptchaForm")
        .find("button[type=submit]")
        .prop("disabled", true)
        .append('<i class="fad fa-spinner-third fa-spin ml-1"></i>');
    $("#reCaptchaForm").trigger("submit");
};

$("input[type=tel], input[inputmode=numeric]").on("input", function (e) {
    $(this).val(
        $(this)
            .val()
            .replace(/[^0-9]/g, "")
    );
});

$("form")
    .not("#reCaptchaForm")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $('input[type="checkbox"]').each(function (i, obj) {
                if (!$(obj).is(':checked')) {
                    $(obj).prop('type', 'hidden').val(0);
                }
            });

            $(this)
                .find("button[type=submit]")
                .prop("disabled", true)
                .append('<i class="fad fa-spinner-third fa-spin ml-1"></i>');
        }
    });

$('.number-only').on('keyup', function () {
    let new_input = $(this).val().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    $(this).val(new_input);
});
